/*** IMPORTS FROM imports-loader ***/
import "core-js/modules/es.promise";
import "core-js/modules/es.array.iterator";

// jQuery
import $ from 'jquery';
$(async function () {

	// Responsive menu
	$('header').each(function () {
		var element = $(this),
			body = $(document.body),
			responsiveNav = $('.responsive-nav');

		var toggler = $('<div class="toggler toggler--closed"><span>Menu</span></div>').click(function () {
			body.toggleClass('open');
			responsiveNav.toggleClass('collapsed');
			toggler.toggleClass('toggler--closed');
		}).appendTo(element);
	});

	// Slideshow
	$('.slideshow').each(async function () {
		await import('lightslider');

		var element = $(this);
		var cssClass = element.attr('class');
		if (element.children('.slide').length > 1) {
			element.lightSlider({
				item: 1,
				slideMargin: 0,
				addClass: cssClass,
				cssEasing: 'linear',
				mode: 'fade',
				auto: true,
				loop: true,
				pause: 6000,
				speed: 1000,
				controls: false,
				pager: true,
				adaptiveHeight: true
			});
		}
	});

	// Slideshow
	$('.slider').each(async function () {
		await import('lightslider');

		var element = $(this);
		var cssClass = element.attr('class');
		if (element.children('li').length > 1) {
			element.lightSlider({
				item: 1,
				slideMargin: 0,
				addClass: cssClass,
				cssEasing: 'linear',
				mode: 'fade',
				auto: true,
				loop: true,
				pause: 6000,
				speed: 1000,
				controls: false,
				pager: true,
				adaptiveHeight: true
			});
		}
	});

	// Match height
	async function matchHeights() {
		await import('jquery-match-height');

		var element = $(this);
		$('.match-height').matchHeight({
			property: 'height'
		});
	}

	if ($('.match-height')[0]) {
		matchHeights();
	}

	$('#houses-form').each(function () {
		var filter = $(this);
		var overview = filter.next();
		var checkboxes = filter.find('input[type=checkbox]');
		var houses = overview.find('.column');

		checkboxes.on('change', function () {
			var selectedValues = $('#houses-form input[type=checkbox]:checked').map(function () { return $(this).val() });
			
			if (selectedValues.length > 0) {
				for (var i = 0; i < houses.length; i++) {

					var hasValue = false;
					for (var j = 0; j < selectedValues.length; j++) {
						if (selectedValues[j] == $(houses[i]).data('region-id')) {
							hasValue = true; 
						}
					}

					if (hasValue) {
						$(houses[i]).show();
					}
					else {
						$(houses[i]).hide();
					}
				}
			}
			else {
				houses.show();
			}
		});

		filter.find('button').hide();
	});

	// Lightbox
	$('#container:has([rel=lightbox])').each(async function () {
		await import('lightgallery');

		$(this).lightGallery({
			selector: '[rel=lightbox]',
			download: false,
			thumbnail: false,
			fullScreen: false,
			actualSize: false,
			hash: false
		});
	});

	// Route form
	$('.form--route').each(function () {
		var element = $(this);
		var button = element.find('button');
		var destinationAddress = element.find('input[type=text]');
		var destination = button.attr('data-address');

		// Route on button click
		button.click(function (e) {
			var route = {
				saddr: destinationAddress.val(),
				daddr: destination.val()
			};

			window.open('https://maps.google.com/?' + $.param(route), '_blank');

			e.preventDefault();
		});
	});

	// Smooth scroll
	$('a[href*=\\#]').click(function () {
		var target = $(this.hash);

		if (!target.length) {
			target = $('[name=' + this.hash.slice(1) + ']');
		}

		if (target.length) {
			$('html, body').animate({
				scrollTop: target.offset().top
			}, 500);

			return false;
		}
	});
});